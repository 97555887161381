<template>
<el-upload
  class="img-uploader"
  accept="image/*"
  :action="uploadImgUrl"
  :headers="headers"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload" name="file">
  <img v-if="imageUrl" :src="imageUrl" class="photo">
  <div v-else >
    <i class="el-icon-plus img-uploader-icon"></i>
    <p class="tip">图片大小不超过2MB</p>
  </div>

</el-upload>
</template>
<script>
  import router from '@/router'
  import { getToken ,removeToken} from "@/utils/auth";
  export default {
    data() {
      return {
        imageUrl: '',
        baseUrl: process.env.VUE_APP_BASE_API,
        uploadImgUrl: process.env.VUE_APP_BASE_API + "/member/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      };
    },
    props: {
      defaultImg: {
        type: String,
        default: null
      },
    },
    model: {
      prop: 'defaultImg',
      event: 'input'
    },
    watch: {
      defaultImg(newValue, oldValue) {
        this.imageUrl =  newValue
      }
    },
    created() {
      if(this.defaultImg){
        this.imageUrl =this.defaultImg
      }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        if(res.code==200){
          this.imageUrl = res.imgUrl
          this.$emit('input',this.imageUrl)
        }else if(res.code==401){
          removeToken();
          this.$alert('登录状态已过期，请重新登录！', '系统提示', {
              confirmButtonText: '确定',
              callback: action => {
                location.href =router.options.base+'/';
              }
            }
          )
        }else {
          this.$message.error(res.msg ?res.msg :'上传失败');
        }
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        this.headers.Authorization ="Bearer " + getToken()
        return isLt2M;
      }
    }
  }
</script>
<style>
  .img-uploader{
    width:132px;
    height: 152px;
    text-align: center;
  }

  .img-uploader .el-upload {
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .img-uploader .el-upload .tip{
    font-size: 12px;
    color: #666666;
  }
  .img-uploader .el-upload:hover {
    border-color: #d9d9d9;
  }
  .img-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    background-color: #FFFFFF;
    width:130px;
    /* height: 150px;
    line-height: 150px; */
    text-align: center;
    border-radius: 6px;
  }
  .photo {
    width:130px;
    height: 150px;
    display: block;
    border-radius: 6px;
    object-fit: cover;
  }
</style>
