import request from '@/utils/request'

// 密码登录
export function login(data) {
  return request({
    url: 'member/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: 'member/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户信息
export function getInfo() {
  return request({
    url: 'member/getInfo',
    method: 'get'
  })
}

//获取招聘栏目信息
export function getRecruitmentTopic(id){
  return request({
    url:'/recruitment/getTopic',
    headers: {
      isToken: false
    },
    method:'get',
    params:{topicId:id}
  })
}

//获取招聘列表
export function getRecruitmentList(params){
  return request({
    url:'/recruitment/list',
    headers: {
      isToken: false
    },
    method:'get',
    params:params
  })
}

//获取招聘详情（带岗位列表）
export function getRecruitmentInfo(recruitmentId,no){
  let url ='';
  if(recruitmentId){
    url = 'recruitment/RecruitmentInfoById/'+recruitmentId;
  }
  if(no){
     url = 'recruitment/RecruitmentInfoByNo/'+no;
  }
  return request({
    url:url,
    headers: {
      isToken: false
    },
    method:'get'
  })
}

//岗位申请
export function applyPost(data){
  return request({
    url: 'registration/from',
    method: 'post',
    data:data
  })
}

//判断是否可以申请岗位
export function checkApply(recrumentId){
  return request({
    url: 'member/verifyApply/'+recrumentId,
    method: 'get',
  })
}

//获取用户提交的报名申请列表
export function getMemberApplyList(params){
  return request({
    url: 'member/memberApply',
    method: 'get',
  })
}

//获取报名的信息详情
export function getApplyInfo(applyId){
  return request({
    url: 'member/selectRecruitRegistrationFrom/'+applyId,
    method: 'get',
  })
}
//更新报名信息
export function updateApplyInfo(data){
  return request({
    url: 'registration/from',
    method: 'put',
    data:data
  })
}
//固定表单提交报名信息 /api/registration/from/addNoForm
export function addNoForm(data){
  return request({
    url: 'registration/from/addNoForm',
    method: 'post',
    data:data
  })
}
//固定表单编辑报名信息 /api/registration/from/editNoForm
export function editNoForm(data){
  return request({
    url: 'registration/from/editNoForm',
    method: 'post',
    data:data
  })
}



//报名缴费
export function createOrder(data){
  return request({
    url: 'recruit/order/orderPay',
    method: 'post',
    data:data
  })
}
//检查支付状态
export function checkPayment(id){
  return request({
    url: 'recruit/order/pay/'+id,
    method: 'get',
  })
}
//下载准考证号
export function getTicket(applyId){
  return request({
    url: 'member/generationTicket/'+applyId,
    method: 'get',
  })
}
//获取资格复审信息
export function getReviewFile(recrumentId){
  return request({
    url: 'member/reviewFile/'+recrumentId,
    method: 'get',
  })
}
//上传资格复审资料
export function uploadReviewFile(data){
  return request({
    url:'member/uploadQualificationReview',
    data:data,
    method: 'post',
  })
}
//获取笔试成绩
export function getWrittenScore(data){
  return request({
    url:'member/selectRecruitScore',
    data:data,
    method: 'post',
  })
}
//发送短信验证码
export function sendVcode(data){
  return request({
    url:'member/senderificationCode',
    params:data,
    method:'post'
  })
}
//忘记密码
export function forgetPassword(data) {
  return request({
    url: 'member/forget',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 字典
export function dict(params) {
  return request({
    url: 'dict/data/type/'+params.dictType,
    method: 'get',
    // params: params,
    // headers: {
    //   isToken: false
    // },
  })
}
//===============

// 验证码登录
export function yzmlogin(data) {
  return request({
    url: 'member/login',
    method: 'post',
    data: data
  })
}


//修改个人信息
export function updateuserinfo(data) {
  return request({
    url: 'artuserinfo/updateuserinfo',
    method: 'post',
    data: data
  })
}




// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCode(phonenumber ) {
  return request({
    url: '/artuser/getyzm',
    method: 'post',
    timeout: 20000,
     params: {
      phonenumber:phonenumber
     }
  })
}

export function getregisteryzm(phonenumber ) {
  return request({
    url: 'artuser/getregisteryzm',
    method: 'post',
    timeout: 20000,
     params: {
      phonenumber:phonenumber
     }
  })
}






// 分类

export function categoryList(params) {
  return request({
    url: 'category/list',
    method: 'get',
    params: params,
    headers: {
      isToken: false
    },
  })
}
// 热门艺术品
export function getHotWorks(limit) {
  return request({
    url: 'artworks/listhot/'+limit,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}

// 最新艺术家
export function getNewArtist(limit) {
  return request({
    url: 'artuser/listNew/'+limit,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}
// 热门艺术家
export function getHotArtist(limit) {
  return request({
    url: 'artuser/listHot/'+limit,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}
// 置顶艺术家
export function getTopArtist(limit) {
  return request({
    url: 'artuser/listTop/'+limit,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}


//作品综合页
export function getRecommendWorks(){
  return request({
    url: 'category/getCategoryHot',
    method: 'get',
    headers: {
      isToken: false
    },
  })
}

//艺术家综合页
export function getRecommendArtists(){

}

//作品详情接口
export function getWorksDetail(id){
  return request({
    url: 'artworks/ArtworkInfo/'+id,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}

//艺术家详情接口
export function getArtistDetail(id){
  return request({
    url: 'artuserinfo/listUserInfo/'+id,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}
//获取艺术家作品信息
export function getArtistWorks(uid ,params){
  return request({
    url: 'artuserinfo/getMyArtWorksById/'+uid,
    method: 'get',
    headers: {
      isToken: false
    },
    params:params
  })
}

//作品搜索列表接口
export function searchWorks(data ,params){
  return request({
    url: 'artuser/serarchByNameOrWorks',
    headers: {
      isToken: false
    },
    method: 'post',
    data:data,
    params:params
  })
}

//艺术家搜索列表接口
export function searchArtists(data ,params){
  return request({
    url: 'artuser/serarchByNameOrWorks',
    headers: {
      isToken: false
    },
    method: 'post',
    data:data,
    params:params
  })
}

//发布作品接口
export function createWorks(data){
  return request({
    url: 'artworks/worksUpload',
    method: 'post',
    data:data
  })
}



//更新我的作品
export function updateWorks(data){
  return request({
    url: 'artuserinfo/myartworksRevise',
    method: 'post',
    data:data
  })
}

//我的作品接口
export function getMyWorks(params){
  return request({
    url: 'artuserinfo/myartworks',
    method: 'get',
    params:params
  })
}

//我的作品详情
export function getMyWorksDetail(id){
  return request({
    url:'artuserinfo/ArtworkInfo/'+id,
    method:'get'
  })
}

//根据分类查询二级分类
export function getCategoryChildren(pid){
  return request({
    url: 'category/getCategory/'+pid,
    headers: {
      isToken: false
    },
    method: 'get'
  })

}

//获取全部分类
export function getAllCategory(){
  return request({
    url: 'category/getAll',
    headers: {
      isToken: false
    },
    method: 'get'
  })

}

//实名认证
export function memberVerify(params){
  return request({
    url: 'artuserinfo/userVerify',
    method: 'post',
    params:params
  })
}

//修改密码
export function updatePassword(params){
  return request({
    url: 'artuserinfo/revisePassword',
    method: 'post',
    params:params
  })
}


//获取文章列表
export function getArticleList(params) {
  return request({
    url: 'cmscontent/list',
    headers: {
      isToken: false
    },
    method: 'get',
    params:params
  })
}

//获取文章详情
export function getArticleDetail(id) {
  return request({
    url: 'cmscontent/'+id,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

//获取验证码-忘记密码

export function getForgetVcode(params) {
  return request({
    url: 'artuser/getForgetPwdVerify',
    headers: {
      isToken: false
    },
    method: 'post',
    params:params
  })
}


//==================

//单点登录，sso login
export function ssoLogin(token) {
  return request({
    url: 'member/ssoLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: {token:token}
  })
}

//单点登录，sso check
export function ssoCheck(token){
  return request({
    url: 'member/ssoCheck',
    headers: {
      isToken: false
    },
    method: 'post',
    data: {token:token},
  })
}

//获取考试成绩
export function getResultList(params){
  return request({
    url: 'recruitment/resultList',
    method: 'get',
    params:params
  })
}
//获取考试成绩详情
export function getResultData(id){
  return request({
    url: 'recruitment/ResultInfo/'+id,
    method: 'get',
  })
}

//获取专项招聘分页数据
export function getTopicList(){
  return request({
    url: 'recruitment/TopicList',
    method: 'get',
  })
}

//获取专项招聘推荐数据
export function getRecommendTopicList(){
  return request({
    url: 'recruitment/RecommendTopicList',
    method: 'get',
  })
}


//获取导航菜单
export function getMemus(){
  return request({
    url: 'recruitment/HomeMenus',
    method: 'get',
  })
}



//获取综合分数详情
export function getScoreData(applyId){
  return request({
    url: 'member/memberApplyDetail/'+applyId,
    method: 'get',
  })
}


//获取入职信息
export function getWorkInfo(recrumentId){
  return request({
    url: 'member/workInfo/'+recrumentId,
    method: 'get',
  })
}
// 查询表单配置详细
export function getConfig(id) {
  return request({
    url: 'recruit/config/' + id,
    method: 'get'
  })
}
//浏览记录

export function addView(data) {
  return request({
    url: 'member/addView',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 导出简历
export function generationApply(id) {
  return request({
    url: '/registration/from/generationApply?applyId=' + id,
    method: 'get'
  })
}


//下载面试准考证号
export function getInterview(applyId){
  return request({
    url: 'member/generationInterview/'+applyId,
    method: 'get',
  })
}
//测试
export function selectRecruitScoreTest(data){
  return request({
    url:'member/selectRecruitScoreTest',
    data:data,
    method: 'post',
  })
}
